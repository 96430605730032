<template>
    <footer>
      <!-- <p>&copy; 2024 Reddinfo. All rights reserved.</p> -->
    </footer>
  </template>
  
  <script>
  export default {
    name: 'AppFooter',
  };
  </script>
  
  <!-- <style scoped>
  footer {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  </style> -->