/* global gtag */
import { processText } from './fetchSubPosts';
import { fetchSubDetails } from './fetchSubAbout';
import { fetchSubRules } from './fetchSubRules';
import { fetchPostsPerDay } from './newPosts';
import { fetchTopContributors } from './fetchTopContributors';

export default {
  data() {
    return {
      selectedOption: 'r/',
      searchInput: '',
      fetchedPosts: [],
      subredditDetails: null,
      subRules: null,
      postsPerDay: [], // For the graph
      contributors: [],
    };
  },
  methods: {
    async handleSearch() {
      const trimmedInput = this.searchInput.trim();

      if (!trimmedInput) {
        alert('Please enter a valid subreddit or username.');
        return;
      }

      // Google Analytics Event Tracking
      this.trackSearchEvent(trimmedInput);

      try {
        // Fetch subreddit details
        this.subredditDetails = await fetchSubDetails(trimmedInput) || null;

        // Fetch Subreddit Rules         
        this.subRules = await fetchSubRules(trimmedInput) || null;

        // Fetch Subreddit posts
        const result = await processText(trimmedInput);
        this.fetchedPosts = Array.isArray(result) ? result : [];

        // Fetch and set posts per day
        this.postsPerDay = await fetchPostsPerDay(trimmedInput);

        // Fetch Top Contributors
        this.contributors = await fetchTopContributors(trimmedInput);
        
      } catch (error) {
        console.error('Error fetching subreddit details and rules:', error);
        this.resetData(); // Reset in case of an error
      }
    },

    trackSearchEvent(searchTerm) {
      if (typeof gtag === 'function') {
        gtag('event', 'searched_subreddit', {
          search_term: searchTerm,
          event_category: 'engagement',
          event_label: 'Search Triggered',
          value: 1,
        });
      } else {
        console.warn('Google Analytics is not loaded yet. Event tracking skipped.');
      }
    },

    resetData() {
      this.subredditDetails = null;
      this.subRules = null;
      this.fetchedPosts = [];
      this.postsPerDay = [];
      this.contributors = [];
    },
  },
};
