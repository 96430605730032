export async function processText(subName) {
  if (!subName) return 'Please enter a valid input!';

  const url = `https://www.reddit.com/r/${subName}/top.json?t=month&limit=5`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error('Post not found or error fetching data.');
    }

    const data = await response.json();

    if (!data.data || !data.data.children || data.data.children.length === 0) {
      return 'No posts found for this query.';
    }

    const posts = data.data.children.map((post) => {
      const postData = post.data;

      // Check for media types
      const hasVideo = postData.is_video;
      const hasGallery = postData.is_gallery || !!postData.gallery_data;

      return {
        title: postData.title,
        description: formatDescription(postData.selftext),
        media_url: hasVideo || hasGallery ? null : postData.url_overridden_by_dest, // Only set media_url if not video or gallery
        score: postData.score.toLocaleString(),
        comments: postData.num_comments.toLocaleString(),
        author: postData.author,
        author_url:`https://www.reddit.com/user/${postData.author}`,
        post_url:`https://www.reddit.com${postData.permalink}`,
        hasVideo,
        hasGallery,
      };
    });

    return posts;

  } catch (error) {
    console.error('Error fetching data:', error);
    return 'Error fetching data from Reddit. Please try again.';
  }
}

// Format description to replace newlines with <br> for proper display
function formatDescription(text) {
  return text.replace(/\n/g, '<br>'); 
}