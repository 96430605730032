export async function fetchSubDetails(subName) {
  if (!subName) return 'Please enter a valid subreddit name!';

  const url = `https://www.reddit.com/r/${subName}/about.json`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Subreddit not found or error fetching data.');
    }

    const data = await response.json();

    // Extract relevant subreddit details
    const subredditDetails = {
      name: data.data.display_name_prefixed,
      title: data.data.title,
      description: data.data.public_description || 'No description available.',
      subscribers: data.data.subscribers.toLocaleString(),
      activeUsers: data.data.active_user_count.toLocaleString(),
      imgPosting: data.data.allow_images,
      url: data.data.url,
      customizeFlair: data.data.user_can_flair_in_sr,
      allowedGalleries: data.data.allow_galleries,
      icon: data.data.community_icon
        ? data.data.community_icon.split('?')[0] 
        : null, // Fallback if icon is not present
    };

    return subredditDetails;

  } catch (error) {
    console.error('Error fetching subreddit details:', error);
    return 'Error fetching subreddit details. Please try again.';
  }
}