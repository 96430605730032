<template>
  <div id="app">
    <main>
      <div class="container-fluid px-3">
        <div class="search-container text-center pt-5">
          <h1 class="display-4">Reddinfo</h1>
          <p class="lead">Fast, Clear Subreddit Insights <br> Without the Clutter</p>
        </div>

        <div class="row justify-content-center pt-3">
          <div class="col-12 col-sm-10 col-md-8 col-lg-6">
            <div class="input-group mb-3">
              <input 
                class="form-control" 
                type="text" 
                placeholder="Enter subreddit name"
                v-model="searchInput" 
                @keydown.enter="handleSearch" 
                ref="searchInput"
                style="outline: none; box-shadow: none;" 
              />
              <button 
                class="btn btn-primary" 
                type="button" 
                @click="handleSearch"
              >
                Search
              </button> 
            </div>
          </div>
        </div>
        <!-- Showing Subreddit Details -->
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
              <subredditDetails :subredditDetails="subredditDetails" />
            </div>
          </div>
        </div>        

        <!-- Showing Subreddit Rules -->
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8">  <!-- col-12 for mobile, col-md-8 for medium screens and up -->
              <subRules :subRules="subRules" />
            </div>
          </div>
        </div>

        <PostsPerDay :postsPerDay="postsPerDay" />

        <!-- Showing Top Contributors -->
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8">
            <TopContributors :contributors="contributors" />
            </div>
          </div>
        </div>

        <!-- Conditional Buy Me a Coffee Button -->
        <div v-if="showBmcButton" class="bmc-container">
          <a href="https://www.buymeacoffee.com/heyumarkhan" target="_blank">
            <img src="https://img.buymeacoffee.com/button-api/?text=Support Reddinfo&emoji=🤝&slug=heyumarkhan&button_colour=38b6ff&font_colour=000000&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00" alt="Support Reddinfo" />
          </a>
        </div>

        <!-- Showing Posts -->
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8">
              <PostList :posts="fetchedPosts" />
            </div>
          </div>
        </div>
        
        <!-- Conditional Buy Me a Coffee Button -->
        <div v-if="showBmcButton" class="bmc-container">
          <a href="https://www.buymeacoffee.com/heyumarkhan" target="_blank">
            <img src="https://img.buymeacoffee.com/button-api/?text=Support Reddinfo&emoji=🤝&slug=heyumarkhan&button_colour=38b6ff&font_colour=000000&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00" alt="Support Reddinfo" />
          </a>
        </div>
        <div v-if="showBmcButton" class="join-subreddit-container">
          <p>
            <strong>Join our subreddit 👉 </strong>
            <a href="https://www.reddit.com/r/reddinfo/" target="_blank" class="subreddit-link">
              r/reddinfo
            </a>
          </p>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from './components/AppFooter.vue';
import PostList from './components/postsView.vue';  // Corrected import to match the component name
import subredditDetails from './components/subAboutView.vue';
import subRules from './components/subRulesView.vue';
import appLogic from './logic/appLogic';
import PostsPerDay from './components/PostsPerDay.vue';
import TopContributors from './components/TopContributors.vue'; // Import the new component

export default {
  components: { 
    AppFooter, 
    PostList, 
    subredditDetails, 
    subRules, 
    PostsPerDay, 
    TopContributors,
  },
  mixins: [appLogic],
  computed: {
    showBmcButton() {
      return this.subredditDetails && this.subRules?.subredditRules && 
             this.subRules.subredditRules.length > 0;
    },
  },
  mounted() {
    this.$refs.searchInput.focus();
  },
};
</script>

<style scoped>

.bmc-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.join-subreddit-container {
  text-align: center;
  font-family: Arial, sans-serif;
  margin-top: 20px;
}

.subreddit-link {
  color: #007acc;
  text-decoration: none;
  font-weight: bold;
}

.subreddit-link:hover {
  text-decoration: underline;
}
</style>