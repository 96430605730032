export async function fetchTopContributors(subreddit) {
    try {
      // Fetch the top 100 posts from the subreddit
      const response = await fetch(`https://www.reddit.com/r/${subreddit}/top.json?limit=100`);
      const data = await response.json();
      console.log("fetching");
      // Check if the response data is valid
      if (!data.data || !data.data.children) {
        throw new Error("No data available");
      }
  
      // Create a map of users and their post counts, karma, and age
      const contributorMap = {};
  
      // Iterate through top posts and count the number of posts by each user
      data.data.children.forEach((post) => {
        const author = post.data.author;
        // If this user is not already in the map, initialize their data
        if (!contributorMap[author]) {
          contributorMap[author] = {
            posts: 0,
            karma: post.data.score, // Karma is the post's score
          };
        }
        contributorMap[author].posts += 1; // Increment post count for this user
      });
  
      // Convert the map to an array of objects and sort by karma in descending order
      const sortedContributors = Object.keys(contributorMap).map((username) => ({
        username,
        karma: contributorMap[username].karma,
        profileLink: `https://www.reddit.com/user/${username}`, // Concatenate username to form the profile URL
      }))
      .sort((a, b) => b.karma - a.karma); // Sort by karma in descending order
  
      // Limit the result to top 5 contributors
      return sortedContributors.slice(0, 5);
  
    } catch (error) {
      console.error("Error fetching top contributors:", error);
      return []; // Return an empty array in case of error
    }
  }