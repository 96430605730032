<template>
  <div class="container mt-4">
    <div v-if="subredditDetails" class="card">
      <div class="card-header d-flex align-items-center">
        <div v-if="subredditDetails.icon" class="me-3">
          <img :src="subredditDetails.icon" alt="Subreddit Icon" class="rounded-circle" width="50" height="50" />
        </div>
        <h2 class="mb-0">{{ subredditDetails.name }}</h2>
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ subredditDetails.title }}</h5>
        <p class="card-text"><strong>Description:</strong> {{ subredditDetails.description }}</p>
        <p class="card-text"><strong>Total Members:</strong> <span class="text-success">{{ subredditDetails.subscribers }}</span></p>
        <p class="card-text"><strong>Active Members:</strong> <span class="text-danger">{{ subredditDetails.activeUsers }}</span></p>
        <p class="card-text"><strong>Allow Images Posting:</strong> {{ subredditDetails.imgPosting ? 'Yes' : 'No' }}</p>
        <p class="card-text"><strong>Customize Flair:</strong> {{ subredditDetails.customizeFlair ? 'Yes' : 'No' }}</p>
        <p class="card-text"><strong>Allow Galleries Posting:</strong> {{ subredditDetails.allowedGalleries ? 'Yes' : 'No' }}</p>
        <a :href="'https://www.reddit.com' + subredditDetails.url" target="_blank" class="btn btn-primary">Visit Subreddit</a>
      </div>
    </div>
    </div>
</template>

<script>
export default {
  name: 'subredditDetails',
  props: ['subredditDetails'], // Accept subredditDetails as a prop
};
</script>
