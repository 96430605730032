<template>
  <div class="container mt-4">
    <!-- Check if subRules data exists -->
    <div v-if="subRules">
      <!-- Display main subreddit rules -->
      <div v-if="subRules.subredditRules && subRules.subredditRules.length" class="subreddit-rules">
        <h2 class="text-center">Subreddit Rules</h2>
        <div class="row">
          <ul class="col-12 list-group">
            <!-- Loop through the main rules and display each one -->
            <li v-for="rule in subRules.subredditRules" :key="rule.ruleNumber" class="list-group-item">
              <strong>{{ rule.title }}</strong><br />
              <p>{{ rule.description }}</p>
              
              <!-- Check if there are sub-points and display them -->
              <ul v-if="rule.subPoints && rule.subPoints.length" class="list-unstyled ms-3">
                <!-- Loop through sub-points and display each one -->
                <li v-for="(subPoint, index) in rule.subPoints" :key="index">
                  - {{ subPoint }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subRules',
  props: ['subRules'], // Accept formatted subRules as a prop
};
</script>

<style scoped>
.subreddit-rules {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #f0f8ff;
  border-radius: 5px;
}

.subreddit-rules h2 {
  margin-bottom: 10px;
  color: #007acc;
}

.subreddit-rules ul {
  margin: 10px 0;
  padding-left: 15px; /* Remove default padding from the list */
}

.subreddit-rules li {
  margin: 5px 0;
}

.subreddit-rules ul ul {
  margin-left: 20px; /* Indentation for nested sub-points */
}

.subreddit-rules .site-rules {
  padding-left: 20px;
}
</style>