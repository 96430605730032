export async function fetchPostsPerDay(subreddit) {
    try {
      const url = `https://www.reddit.com/r/${subreddit}/new.json?limit=100`;
      const response = await fetch(url);
      const data = await response.json();
  
      if (!data || !data.data || !data.data.children) return [];
  
      const posts = data.data.children.map(post => ({
        timestamp: post.data.created_utc * 1000, // Convert to milliseconds
        date: new Date(post.data.created_utc * 1000).toISOString().split("T")[0],
      }));
  
      // Group posts by day or hour
      const postsPerDay = posts.reduce((acc, post) => {
        const date = post.date;
        const hour = new Date(post.timestamp).getHours();
        const label = posts.length <= 48 
          ? `${date} ${hour}:00`  // Format for hourly data
          : date;  // For longer time ranges, just use the date
  
        acc[label] = (acc[label] || 0) + 1;
        return acc;
      }, {});
  
      // Format the data for chart usage
      return Object.entries(postsPerDay).map(([label, count]) => {
        const dateObj = new Date(label.split(" ")[0]);
        return {
          date: label.split(" ")[0],
          timestamp: dateObj.getTime(),
          label,  // Will either be "2024-12-10" or "2024-12-10 12:00"
          count,
        };
      });
    } catch (error) {
      console.error("Error fetching posts per day:", error);
      return [];
    }
  }